@import '../../assets/scss/app-variables';

.half-bg {
  background: linear-gradient(290.33deg, #FEDEDD 6.97%, #FFC8C8 56.56%, #FFB29A 96.71%);
  position: fixed;
  right: 0px;
  top: 0px;
  width: 50%;
  height: 100%;
  z-index: 1;

  &.bgColor {
    background: $invalid-background-color;
  }
}

.slideContainer {
  &.invalidBgColor {
    background-color: $invalid-background-color;
  }

  &.validBgColor {
    background-color: $valid-background-color;
  }

  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;

    img {
      width: 65px;
      height: 13px;
    }
  }
}

.result-container {
  display: flex;
  height: calc(100% - 13px);

  .slideBodyResult {
    display: flex;
    align-items: center;
    flex-direction: column;

    .content {
      .childContent {
        align-items: center;
        display: flex;
        flex-direction: column;
        
        h2 {
          margin-bottom: $space-between;
          text-align: center;

          &.colorWhite {
            color: $white;
          }
        }

        p.description {
          margin-bottom: $space-between-x2;
          text-align: center;
        }

        img {
          width: 86px;
          height: 84px;
        }
      }
    }
  }

  .motorcycle {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-right: -20px;
    padding: 0;
    width: calc(50% + 20px);
    
    img {
      width: 578px;
    }
  }

  .hiddenPerson {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    margin-right: -20px;
    padding: 0;
    width: calc(50% + 20px);

    &.smallHiddenPerson {
      margin-bottom: -20px;
      margin-left: -20px;
      margin-right: 0;
      width: calc(100% + 40px);
    }
    
    img {
      width: 100%;
    }
  }
}

@include screen('large') {
  .result-container {
    justify-content: space-between;

    .slideBodyResult {
      justify-content: center;
      width: calc(50% - 20px);

      .content .childContent {
        width: 350px;
      }
    }
  }
}
  
@include screen('small') {
  .slideContainer {
    .logoContainer {
      display: none;
    }
  }

  .result-container {
    height: calc(100% - 25px);
    flex-direction: column;

    .slideBodyResult {
      height: 100%;

      .content .childContent p.description {
        margin-bottom: $space-between;
      }
    }

    .motorcycle {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: 0;
      width: 100%;
      
      img {
        width: 289px;
      }
    }

    .treeContainer {
      display: none;
    }
  }
}