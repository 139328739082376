@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;900&display=swap');

/* typography */
$main-font-family: 'Poppins', sans-serif;

/* color */
$primary-blue: #063e75;
$white: #fff;
$valid-background-color: #ffecec;
$invalid-background-color: #082c54;

/* Space between */
$space-between: 20px;
$space-between-x2: 40px;

/* responsive */
$breakpoints: (
  'small': ('min': 0px, 'max': 415px),
  'large': ('min': 416px, 'max': 2560px)
);

/* @mixin managing breakpoint medias */
@mixin screen($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @each $key, $props in $breakpoints {
      @if $key == $breakpoint {
        @media (min-width: map-get($props, 'min')) and (max-width: map-get($props, 'max')) {
          @content;
        }
      }
    }
  } @else {
      @warn "Unfortunately, `#{$breakpoint}` can not be found. "
        + "Available breakpoints are : #{map-keys($breakpoints)}";
  }
}